import {
  Box,
  ChakraProvider,
  Fade,
  Image,
  Text,
  Center,
  HStack,
  Icon,
  useBreakpointValue
} from '@chakra-ui/react';
import { React, useEffect, useState } from 'react';
import Carousel from "../Components/Carousel.tsx";
import { bigTitleSize, customTheme, normalTextSize, subTitleSize, titleFont, smallTextSize } from '../CustomTheme';
import { GetData, root } from "../services/cmsAPI";
import { NewsOverview } from './news.js';
import { BlockMargin} from './page.js'
import {
  FiArrowDown,
} from 'react-icons/fi';


export function Home(props) {
  const[timeOut, setTimeOutTime] = useState(true);
  const{data,loading} = GetData("/api/home-page?populate=*");
  let logoIn = !loading;

  useEffect(() => {
    if(!loading)
      {
      setTimeout(() => {
        setTimeOutTime(false)
        logoIn=false;
          }, 1250);
      }

  },[loading]);

    return(
<ChakraProvider theme={customTheme}>
{loading ? 
            <></>
        : 
        <>
          <Fade in = {timeOut} out={!timeOut} unmountOnExit = 'true'  transition={{enter:{duration: .25}, exit: { duration: .25 } }}>
            <LogoPage data = {data} loading = {loading}/>
          </Fade>
          <InfoPage data={data} articleData = {props.articleData} in={!timeOut} out={timeOut}/>
        <BlockMargin/>
        <Center><HStack spacing='5dvw'><Text color='white' fontSize={smallTextSize}>© Scouts Sint-Kristoffel</Text><Text color='white' fontSize={smallTextSize}>Development en design: Quinten van Diessen</Text></HStack></Center>
        </>
        }
</ChakraProvider>
    )
}

const LogoPage = (props) => {
    let landingPageLogo = <></>
    if (props.loading==false)
    {
      landingPageLogo = 
      <Image 
      maxH='65dvh'
      maxW='65dvw'
      src= {root.concat(props.data.attributes.LandingPageLogo.data.attributes.formats.small.url)}
      alt='Logo' 
      position='fixed'
      top = '25dvh'
      />
    }
  return (
    <Box bg = "primary.800" overflowY="hidden" minH="100vh" display='flex' justifyContent='center' alignItems = 'center'>
      {landingPageLogo}
    </Box>
  );
};

const SlideShow = (props) =>{
  let slideshowImages = [];
  for (var k = 0; props.data.attributes.Slideshow.data[k]; k++){
    slideshowImages[k] = root.concat(props.data.attributes.Slideshow.data[k].attributes.formats.large.url)
  }
  return(
    <Carousel images={slideshowImages}/>
  )
}

const InfoPage = (props) =>{
  return(
    <Fade in = {props.in} out={props.out} transition={{enter:{duration: .25, delay: .25}, exit: { duration: .25 }}}>
      <Box bg = "primary.800" textAlign="center">
          <Text color='white' as = 'b' fontWeight = '900' fontSize={bigTitleSize} fontFamily={titleFont}>
            {props.data.attributes.Title}
          </Text>
          <Text></Text> 
          <Text color='white' as ='cite' fontSize={subTitleSize} fontFamily={titleFont}>
            {props.data.attributes.Subtitle}
          </Text>
          <Box minH='4dvh'></Box>
          <Box borderRadius='xl'>
            <SlideShow data = {props.data}/>
          </Box>
          <BlockMargin/>
          <SubTitle text='Nieuws'/>
          <NewsOverview data = {props.articleData}/>
      </Box>
      </Fade>

  )
}

export const SubTitle = (props) => 
  {
    const iconSize = useBreakpointValue({ base: "25px", md: "40px" });
    return(
                            <Center>
                            <Box textAlign='center' display="inline-block" bg='gray.900' rounded='md' >
                            <HStack>
                                <Text m={2} color='white' fontSize={subTitleSize} fontFamily = {titleFont}>
                                      {props.text.toUpperCase()}
                                </Text>
                                <Icon as={FiArrowDown} color='white' boxSize={iconSize}/>
                            </HStack>
                            </Box>
                            </Center>
    )
  }