import {React,
    useState,
    useEffect} from 'react';

export const headers = { "Content-Type": "application/json" };

export const parseJSON = (resp) => (resp.json ? resp.json() : resp);

export const checkStatus = (resp) => {
    if (resp.status >= 200 && resp.status < 300) {
      return resp;
    }
  
    return parseJSON(resp).then((resp) => {
      throw resp;
    });
  };

export const root = "https://api.sint-kristoffel.be"

//General fetch function
export function FetchData(url, setData, setLoading){
  const path = root.concat(url);
  fetch(path, { headers, method: "GET" })
  .then(checkStatus)
  .then(parseJSON)
  .then(({ data }) => {setData(data); 
                      setLoading(false);})
  .catch((error) => {
    console.error('Error fetching data:', error);
  }); 
}

//The below method is meant to exported and be used to fetch in react functional components
export const GetData = (url) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
      FetchData(url, setData, setLoading);
    }, []);
    return {data,loading};
  };

//The below function fetches the pages data in combination with the menu-order and hierarchy
export const FetchMenuData = () => {
  let pages = [];
  let subsections = [];
  let URLpages = [];
  let URLsubsections = [];
  const [dataPages, setDataPages] = useState(null);
  const [dataOrder, setDataOrder] = useState(null);
  const [loadingPages, setLoadingPages] = useState(true);
  const [loadingOrder, setLoadingOrder] = useState(true);


  useEffect(() => {
    FetchData("/api/pages?sort[0]=publishedAt:asc&populate[ParentPage][populate][0]=pages&populate[PageIcon][populate][0]=pages",setDataPages,setLoadingPages)
    FetchData("/api/nav-bar-order?populate=*&_sort=order:ASC",setDataOrder,setLoadingOrder)
  }, []);

const loadingMenu = (loadingPages||loadingOrder)

if (!(loadingMenu))
{
  for (var i = 0; dataOrder.attributes.pages.data[i]; i++){
      for (var j = 0; dataPages[j]; j++){
          if (dataOrder.attributes.pages.data[i].id == dataPages[j].id 
              && (dataPages[j].attributes.ParentPage.data==null))
          {
              pages.push(dataPages[j]);
              URLpages.push(dataPages[j].attributes.URLsegment)
              let subpages = [];
              let URLsubpages = [];
              for (var k = 0; dataPages[k]; k++){
                  if(dataPages[k].attributes.ParentPage.data!=null 
                      && dataPages[k].attributes.ParentPage.data.id == dataPages[j].id)
                  {
                      subpages.push(dataPages[k])
                      URLsubpages.push(dataPages[k].attributes.URLsegment)
                  }
              }
              subsections.push(subpages);
              URLsubsections.push(URLsubpages)
          }
      }
  }
}
  return {pages,subsections,URLpages,URLsubsections,loadingMenu};
}      


 