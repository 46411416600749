'use client'
import React from 'react'
import {
  Box,
  Center,
  Heading,
  Text,
  Stack,
  Avatar,
  useColorModeValue,
  Image,
  BoxProps
} from '@chakra-ui/react'
import { BlocksRenderer } from '@strapi/blocks-react-renderer';
import {NavLink} from "react-router-dom";
import {convertToSlug} from '../pages/news.js';


interface Props extends BoxProps {
    image: Array<string>;
    title: string;
    date: string;
    text: any;
    onClose: () => void;
  };

export default function BlogPostWithImage({image,title, date, text, onClose}: Props) {
  console.log(date)
  return (
    <Center py={6} marginRight='3dvw' marginLeft="3dvw">
      <NavLink to = {convertToSlug(title)} onClick={onClose}>
      <Box
        maxW={'445px'}
        w={'full'}
        // eslint-disable-next-line react-hooks/rules-of-hooks
        bg='gray.900'
        boxShadow={'2xl'}
        rounded={'md'}
        p={6}
        overflow={'hidden'}
        _hover={{ 
          bg: "gray.200",
          transform: "scale(1.02)",
          transition: "all 0.2s ease-in-out",
        }}
        _active={{
          bg: "gray.500",
          transform: "scale(0.98)",
        }}>
        <Box h={'210'} bg={'gray.100'} mt={-6} mx={-6} mb={6} pos={'relative'} overflow='hidden'>
          <Image
            src={image.toString()}
            objectFit='cover'
            width='100%'
            height='100%'
            alt="Example"
          />
        </Box>
        <Stack>
          <Text
            color={'orange.400'}
            textTransform={'uppercase'}
            fontWeight={800}
            fontSize={'sm'}
            letterSpacing={1.1}>
            Nieuws
          </Text>
          <Heading
            // eslint-disable-next-line react-hooks/rules-of-hooks
            color='white'
            fontSize={'2xl'}
            fontFamily={'body'}>
            {title}
          </Heading>
          <Box maxW = '90%' maxH='90%' marginLeft='5%' marginRight='5%' sx = {{display: '-webkit-box',
            WebkitBoxOrient: 'vertical', WebkitLineClamp: 4,overflow: 'hidden',}} textColor='white'>
            <BlocksRenderer content = {text}/>
          </Box>
        </Stack>
        <Stack mt={6} direction={'row'} spacing={4} align={'center'}>
          <Avatar src={'https://api.sint-kristoffel.be/uploads/thumbnail_logo_met_mannetje_web_Tekengebied_1_d1d8e927e7.png'} />
          <Stack direction={'column'} spacing={0} fontSize={'sm'}>
            <Text fontWeight={600} textColor='white'>Groepsleiding</Text>
            //<Text color={'white'}>{date.slice(8,10).concat('/').concat(date.slice(5,7)).concat('/').concat(date.slice(0,4))}</Text>
          </Stack>
        </Stack>
      </Box>
      </NavLink>
    </Center>
  )
}