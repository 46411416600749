import {
    Box,
    Center
} from '@chakra-ui/react';
import {
    marginsLeft,
    marginsRight,
} from '../CustomTheme';
import { GetData, } from '../services/cmsAPI';
import {PageTitle,SubTitle, BlockMargin} from './page.js'

export function Bazuin(props){
    const{data,loading} = GetData("/api/bazuins");

    return(
        loading?
        <></>
        :
        <>
        <PageTitle text={"Den Bazuin".toUpperCase()}/>  
                        <Box marginLeft={marginsLeft} marginRight={marginsRight} bg='yellow.200' rounded='md' boxShadow = 'dark-lg' minH = '80dvh' overflow='hidden'>
                                <BlockMargin/>
                                <>{data.map((e,i) => 
                                    <>
                                    <SubTitle text={e.attributes.Maand}/>
                                    <BlockMargin/>
                                    <Center>
                                        <iframe
                                            src={e.attributes.GoogleDriveURL}
                                            style={{marginLeft: '2dvw', marginRight: '2dvw', width: '100%', height: '100dvh' }}
                                            frameborder="1"
                                            >
                                            This browser does not support PDFs. Please download the PDF to view it: 
                                            <a href={e.attributes.GoogleDriveURL}>Download PDF</a>
                                        </iframe>
                                    </Center>
                                    <BlockMargin/>
                                    </>
                                )}</> 
                        </Box>
                        <BlockMargin/>
        </>)
            }